<script setup lang="ts">
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import { useProvider } from '/~/composables/provider'

const { isBillPaymentsV15Template } = useProvider()
const { page } = useEditablePage()
</script>

<template>
  <div class="flex min-h-full flex-col sm:flex-row">
    <div
      class="flex min-h-full w-full grow flex-col overflow-auto bg-eonx-neutral-50 sm:w-2/3"
      :class="{
        'sm:rounded-t-3xl': !isBillPaymentsV15Template,
      }"
    >
      <router-view />
    </div>
    <div
      v-if="page && page.widgets.length > 0"
      class="w-max-96 hidden sm:block"
      :class="{ 'px-[30px]': page && page.widgets }"
    >
      <widgets-builder
        :widgets="page.widgets"
        side="right"
        class="mb-10 !space-y-5"
      />
    </div>
  </div>
</template>
